const getPlaceholder = (clientState: string, serverState: string, streaming: boolean): string => {
    // 'waiting' | 'editable' | 'connecting'
    if (clientState === "waiting") {
        if (serverState === 'STATE_WAITING_FOR_INIT') {
            return 'Getting ready...';
        } else if (serverState === 'STATE_CHATTING' && streaming === true) {
            return 'StackHub is typing...';
        } else if (serverState === 'STATE_CHATTING' && streaming === false) {
            return 'Waiting for answer...';
        } else if (serverState === 'STATE_ANSWERED') {
            return 'Waiting for answer...';
        } else {
            return 'Waiting for answer...';
        }
    }
    else if (clientState === "connecting") {
        return 'Connecting...';
    }
    else if (clientState === "editable") {
        return 'Send a message';
    }
    else {
        console.error("Unknown clientState:", clientState);
        return 'Something went wrong...';
    }
};

export default getPlaceholder;
