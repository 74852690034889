import { Platform } from "react-native";

export const SENTRY_DATA_SOURCE_NAME = "https://056175ac9e6d40fa8d4e605063ed8346@o4505205638430720.ingest.sentry.io/4505205719040000";

let wsServerEndpoint = "wss://api.stackhub.com/ws";
if (__DEV__ && Platform.OS === "web") {
    wsServerEndpoint = "ws://127.0.0.1:6767/ws" // TODO @@@@ "ws://192.168.1.2:6767/ws";
}

export const WS_SERVER_ENDPOINT = wsServerEndpoint;
export const RESPONSE_TIMEOUT_MS = 60 * 1000;
