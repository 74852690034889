import { WebSocketStatus } from "../hooks/useWebSocket";

const getStatusDisplay = (status: WebSocketStatus) => {
    let statusDisplay;
    switch (status.type) {
      case 'connecting':
        statusDisplay = 'Connecting';
        break;
      case 'error':
        statusDisplay = 'An error occurred, please restart the app.';
        break;
      case 'reconnecting':
        if (status.nextAttempt) {
          const timeUntilNextAttempt = Math.max(status.nextAttempt - Date.now(), 0);
          statusDisplay = `Reconnecting in ${Math.ceil(timeUntilNextAttempt / 1000)} seconds...`;
        } else {
          statusDisplay = 'Reconnecting...';
        }
        break;
      default:
        statusDisplay = 'An error occurred, please restart the app.';
    }
    return statusDisplay;
  }
  
  export default getStatusDisplay;