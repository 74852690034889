import React, { useState, useEffect, useContext } from 'react';
import { View, TextInput, Linking, Platform, Button } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import styles, { MyText } from '../styles';
import Toast from 'react-native-toast-message';
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { WebSocketContext } from '../contexts/webSocketContext';
import * as Application from 'expo-application';
import * as Updates from 'expo-updates';
import { WS_SERVER_ENDPOINT } from '../main/constants';

interface Props {
}

const Account: React.FC<Props> = () => {
    const { ws, status } = useContext(WebSocketContext);
    const [key, setKey] = useState<string>('');
    const [keyStored, setKeyStored] = useState<boolean>(false);
    const navigation = useNavigation();

    useEffect(() => {
        const fetchKey = async () => {
            let storedKey = '';
            if (Platform.OS === 'web') {
                storedKey = await AsyncStorage.getItem('openAIKey') || '';
            } else {
                storedKey = await SecureStore.getItemAsync('openAIKey') || '';
            }
            const keyFormat = new RegExp('^sk-[a-zA-Z0-9]{32,}$');
            if (keyFormat.test(storedKey)) {
                setKey(storedKey);
                setKeyStored(true);
            }
        }
        fetchKey();
    }, []);

    const deleteKey = async () => {
        if (Platform.OS === 'web') {
            await AsyncStorage.removeItem('openAIKey');
        } else {
            await SecureStore.deleteItemAsync('openAIKey');
        }
        setKey('');
        setKeyStored(false);
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.close();
        }
    }

    const storeKey = async (inputKey: string) => {
        const keyFormat = new RegExp('^sk-[a-zA-Z0-9]{32,}$');

        if (!keyFormat.test(inputKey)) {
            Toast.show({
                type: 'error',
                text1: 'Invalid OpenAI API key',
                autoHide: true,
                visibilityTime: 5000,
                topOffset: 300,
            });
        } else {
            if (Platform.OS === 'web') {
                await AsyncStorage.setItem('openAIKey', inputKey);
            } else {
                await SecureStore.setItemAsync('openAIKey', inputKey);
            }
            setKeyStored(true);
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
            Toast.show({
                type: 'success',
                text1: 'Stored OpenAI API key on device',
                autoHide: true,
                visibilityTime: 3000,
                topOffset: 300,
            });
        }
    }

    return (
        <View style={styles.container}>
            <View style={[styles.settingsArea, styles.contentWrapper]}>
                <TextInput
                    style={styles.openaiKeyInput}
                    onChangeText={setKey}
                    value={key}
                    placeholder="Paste OpenAI API Key and hit enter"
                    secureTextEntry={true}
                    onSubmitEditing={() => storeKey(key)}
                />
                {!keyStored && <MyText style={styles.openaiInfoText}>
                    If you don't have an OpenAI API key, get one here:{" "}
                    <MyText
                        style={styles.openaiLinkText}
                        onPress={() => Linking.openURL('https://platform.openai.com/account/api-keys')}
                    >
                        https://platform.openai.com/account/api-keys
                    </MyText>
                </MyText>}
                {keyStored && <MyText style={styles.openaiInfoText}>OpenAI API key is stored on device.{" "}
                    <MyText
                        style={styles.openaiLinkText}
                        onPress={deleteKey}
                    >
                        Delete key
                    </MyText>
                </MyText>}
            </View>
            <View style={styles.settingsArea}>
                <View style={styles.settingRow}>
                    <MyText style={styles.settingLabel}>App version:</MyText>
                    <MyText style={styles.settingValue}>{Application.nativeApplicationVersion}</MyText>
                </View>
                <View style={styles.settingRow}>
                    <MyText style={styles.settingLabel}>Build number:</MyText>
                    <MyText style={styles.settingValue}>{Application.nativeBuildVersion}</MyText>
                </View>
                <View style={styles.settingRow}>
                    <MyText style={styles.settingLabel}>Channel:</MyText>
                    <MyText style={styles.settingValue}>{Updates.channel}</MyText>
                </View>
                <View style={styles.settingRow}>
                    <MyText style={styles.settingLabel}>WS backend:</MyText>
                    <MyText style={styles.settingValue}>{WS_SERVER_ENDPOINT}</MyText>
                </View>
                <View style={styles.settingRow}>
                    <Button onPress={() => navigation.goBack()} title="Dismiss" />
                </View>
            </View>
        </View>
    );
};

export default Account
