import { Platform, StyleSheet, Text as DefaultText } from 'react-native';

export const grey25 = "#FCFCFD";
export const grey50 = "#F9FAFB";
export const grey100 = "#F2F4F7";
export const grey200 = "#EAECF0";
export const grey300 = "#D0D5DD";
export const grey400 = "#98A2B3";
export const grey500 = "#667085";
export const grey600 = "#475467";
export const grey700 = "#344054";
export const grey800 = "#1D2939";
export const grey900 = "#101828";

export const blue25 = "#FCFCFD";
export const blue50 = "#F8F9FC";
export const blue100 = "#EAECF5";
export const blue200 = "#D5D9EB";
export const blue300 = "#AFB5D9";
export const blue400 = "#717BBC";
export const blue500 = "#4E5BA6";
export const blue600 = "#3E4784";
export const blue700 = "#363F72";
export const blue800 = "#293056";
export const blue900 = "#101323";

export const notification = "#FF3B30";

export type TextProps = DefaultText['props'];

export const MyText = (props: TextProps) => {
    const { style, ...otherProps } = props;
    const color = grey900;
    const family = { 'fontFamily': 'regular' };
    return <DefaultText style={[{ color }, family, style]} {...otherProps} />;
}

export const Theme = {
    dark: false,
    colors: {
        primary: blue500,
        background: grey25,
        card: grey100,
        text: grey900,
        border: grey300,
        notification: notification,
    },
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: grey25,
    },
    outputArea: {
        flex: 1,
        padding: 10,
    },
    contentWrapper: {
        maxWidth: 750,
        alignSelf: 'center',
        flexGrow: 1,
        width: '100%',
    },
    inputWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 750,
        width: '100%',
        alignSelf: 'center',
    },
    inputArea: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 10,
        ...Platform.select({
            ios: {
                paddingBottom: 0,
                paddingLeft: 30,
                paddingRight: 30,
            },
            android: {
                paddingBottom: 10,
                paddingLeft: 30,
                paddingRight: 30,
            },
            default: {
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
            },
        }),
        backgroundColor: grey25,
        borderTopWidth: 1,
        borderTopColor: grey300,
        flexWrap: 'nowrap',
    },
    textInput: {
        backgroundColor: grey100,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderRadius: 5,
        fontSize: 16,
        width: '90%',
        maxHeight: 200,
        marginHorizontal: 10,
    },
    disabledInput: {
        backgroundColor: grey300,
        color: grey400,
    },
    message: {
        width: '100%',
        alignSelf: 'stretch',
        backgroundColor: grey100,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        marginBottom: 10,
        padding: 10,
    },
    messageContent: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: '100%',
    },
    customContent: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        marginVertical: 5,
    },
    customText: {
        fontSize: 12
    },
    buttonContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        paddingVertical: 10,
    },
    actionButton: {
        borderWidth: 1,
        borderColor: blue400,
        backgroundColor: 'transparent',
        paddingHorizontal: 10,
        paddingVertical: 2,
        borderRadius: 5,
        margin: 10,
    },
    actionButtonText: {
        color: blue400,
    },
    emptyDiscoverContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyDiscoverText: {
        fontSize: 24,
        color: grey700,
        paddingVertical: 10,
    },
    messageIcon: {
        color: blue500,
        ...Platform.select({
            ios: {
                marginTop: 4,
            },
            android: {
                marginTop: 5,
            },
            default: {
                marginTop: 1,
            },
        }),
    },
    openInNewIcon: {
        color: blue400,
        marginTop: 1,
        marginLeft: 5,
    },
    settingsArea: {
        padding: 30,
        flex: 1,
    },
    settingRow: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    settingLabel: {
        fontSize: 16,
    },
    settingValue: {
        fontSize: 16,
        flexShrink: 1,
        paddingHorizontal: 10
    },
    openaiKeyInput: {
        height: 50,
        width: '100%',
        borderColor: grey500,
        borderWidth: 1,
        paddingLeft: 10,
        borderRadius: 5,
        backgroundColor: grey25,
    },
    openaiInfoText: {
        color: grey500,
        fontSize: 12,
        marginTop: 10,
    },
    openaiLinkText: {
        color: blue400,
    },

});

export default styles;
