import { SafeAreaView, View } from 'react-native';
import styles, { MyText } from '../styles';

const ShortList = () => {
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.settingsArea}>
        <View style={styles.settingRow}>
          <MyText style={styles.settingLabel}>Screen</MyText>
          <MyText style={styles.settingValue}>Short list</MyText>
        </View>
      </View>
    </SafeAreaView>
  );
}

export default ShortList;
