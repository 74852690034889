import 'react-native-gesture-handler';
import { useCallback } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as SplashScreen from 'expo-splash-screen';
import { useFonts, Inter_400Regular, Inter_500Medium, Inter_600SemiBold, Inter_700Bold } from '@expo-google-fonts/inter';
import Ionicons from '@expo/vector-icons/Ionicons';
import { useWebSocket } from './hooks/useWebSocket';
import { WebSocketContext } from './contexts/webSocketContext';
import Discover from './screens/discover';
import ShortList from './screens/shortlist';
import Compare from './screens/compare';
import Account from './screens/account';
import Toast from 'react-native-toast-message';
import { WS_SERVER_ENDPOINT } from './main/constants';
import * as C from './styles'
import { Theme } from './styles';
import Icon from './components/icon';

// TODO asset caching https://docs.expo.dev/archive/classic-updates/preloading-and-caching-assets/#pre-loading-and-caching-assets

SplashScreen.preventAutoHideAsync();

const App = () => {
  // Use localtunnel to expose your local server to the internet
  // const { ws, status } = useWebSocket("ws://192.168.1.16:8000/ws");
  const { ws, status } = useWebSocket(WS_SERVER_ENDPOINT);

  const [fontsLoaded] = useFonts({
    'regular': Inter_400Regular,
    'medium': Inter_500Medium,
    'semibold': Inter_600SemiBold,
    'bold': Inter_700Bold,
    ...Ionicons.font,
    IcoMoon: require('./assets/icomoon/icomoon.ttf'),
  });

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  const Stack = createNativeStackNavigator();
  const Tab = createBottomTabNavigator();

  const TabNavigator = () => {
    return (
      <Tab.Navigator
        initialRouteName="Discover"
        screenOptions={({ navigation, route }) => ({
          tabBarIcon: ({ focused, color, size }) => {
            let iconName = 'alert-circle'; // default

            if (route.name === 'Discover') {
              iconName = focused
                ? 'chatbox'
                : 'chatbox-outline';
            } else if (route.name === 'ShortList') {
              iconName = focused ? 'ios-list-circle' : 'ios-list-circle-outline';
            } else if (route.name === 'Compare') {
              iconName = focused ? 'git-compare' : 'git-compare-outline';
            }

            // @ts-ignore
            return <Ionicons name={iconName} size={size} color={color} />;
          },
          tabBarActiveTintColor: C.blue500,
          tabBarInactiveTintColor: C.grey500,
          tabBarLabelStyle: {
            fontFamily: 'regular',
          },
          headerLeft: () => <View style={styles.logo}><Icon name="logo" size={32} color={C.blue500} onPress={() => navigation.navigate('Discover')} /></View>,
          headerRight: () => <View style={styles.menuIcon}><Ionicons name="ios-person-circle" size={28} color={C.blue500} onPress={() => navigation.navigate('Account')} /></View>,
          headerStyle: {
            ...Platform.select({
              ios: {
                shadowColor: Theme.colors.text,
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.3,
                shadowRadius: 3,
              },
              android: {
                elevation: 4,
              },
              default: {
                boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
              },
            }),
          },
          headerBackTitleStyle: {
            fontFamily: 'regular',
            color: C.blue600,
          },
          headerTitleStyle: {
            fontFamily: 'bold',
            fontWeight: 'bold',
            color: C.blue600,
          },
        })
        }
      >
        <Tab.Screen name="Discover" component={Discover} options={{ headerTitle: 'StackHub' }} />
        <Tab.Screen name="ShortList" component={ShortList} options={{ tabBarBadge: 3, tabBarBadgeStyle: { backgroundColor: Theme.colors.notification, color: C.grey25 } }} />
        <Tab.Screen name="Compare" component={Compare} />
      </Tab.Navigator>
    )
  }

  type RootStackParamList = {
    Tabs: undefined;
    Account: undefined;
    Discover: undefined;
    ShortList: undefined;
    Compare: undefined;
  };

  const linking: LinkingOptions<RootStackParamList> = {
    prefixes: ['https://stackhub.com', 'stackhub://'],
    config: {
      screens: {
        Tabs: {
          screens: {
            Discover: '',
            ShortList: 'shortlist',
            Compare: 'compare',
          },
        },
        Account: 'account',
      },
    },
  };

  return (
    <WebSocketContext.Provider value={{ ws, status }}>
      <NavigationContainer onReady={onLayoutRootView} theme={Theme} linking={linking}>
        <Stack.Navigator>
          <Stack.Group>
            <Stack.Screen name="Tabs" component={TabNavigator} options={{ headerShown: false }} />
          </Stack.Group>
          <Stack.Group screenOptions={{ presentation: 'modal' }}>
            <Stack.Screen name="Account" component={Account} options={{ headerShown: false }} />
          </Stack.Group>
        </Stack.Navigator>
      </NavigationContainer>
      <StatusBar style="auto" />
      <Toast />
    </WebSocketContext.Provider>
  );
}

const styles = StyleSheet.create({
  menuIcon: {
    marginRight: 15,
  },
  logo: {
    marginLeft: 15,
  },
});

export default App;
