import React from 'react';
import { Linking, StyleProp, TextStyle } from 'react-native';
import { MyText } from '../styles';

const parseMarkdown = (text: string, style?: StyleProp<TextStyle>) => {
    const parts = text.split(/(\[.+\]\(.+\))/);
    let number = 1;
    return parts.map((part, index) => {
        const urlMatch = part.match(/\[(.+)\]\((.+)\)/);
        if (urlMatch) {
            return (
                <MyText
                    key={index}
                    onPress={() => {
                        Linking.openURL(urlMatch[2])
                    }}
                    style={{ color: '#006eff' }}
                >
                    {urlMatch[1]}
                </MyText>
            );
        } else if (part.startsWith('* ')) {
            // replace '*' at the beginning of a line with a bullet point
            const bulletText = part.split('\n');
            return bulletText.map((line, i) => {
                if (line.startsWith('* ')) {
                    line = '\u2022 ' + line.slice(2);
                    return <MyText style={style} key={i}>{line}</MyText>;
                }
                return <MyText style={style} key={i}>{line}</MyText>;
            });
        } else if (part.match(/^\d+\./)) {
            // replace '# ' at the beginning of a line with a numbered list
            const numberedListText = part.split('\n');
            return numberedListText.map((line, i) => {
                if (line.match(/^\d+\./)) {
                    line = number + '. ' + line.slice(line.indexOf('.') + 1).trim();
                    number++;
                    return <MyText style={style} key={i}>{line}</MyText>;
                }
                return <MyText style={style} key={i}>{line}</MyText>;
            });
        } else {
            return <MyText style={style} key={index}>{part}</MyText>;
        }
    });
};


export default parseMarkdown;
