import _ from 'lodash';
import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { ActivityIndicator, Keyboard, KeyboardAvoidingView, Platform, SafeAreaView, ScrollView, TextInput, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useFocusEffect, useTheme } from '@react-navigation/native';
import Toast from 'react-native-toast-message';
import Ionicons from '@expo/vector-icons/Ionicons';
import { WebSocketContext } from '../contexts/webSocketContext';
import parseMarkdown from '../util/parseMarkdown';
import { useHeaderHeight } from '@react-navigation/elements';
import getStatusDisplay from '../util/getStatusDisplay';
import adjustTextInputSizeWeb from '../util/adjustTextInputSizeWeb';
import styles, { MyText } from '../styles';
import { captureMessage } from '../util/sentry';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import { RESPONSE_TIMEOUT_MS } from '../main/constants';
import isMobileDevice from '../util/isMobileDevice';
import * as C from '../styles'
import getPlaceholder from '../util/getPlaceholder';
//import Icon from '../components/icon';

// TODO - add a "share" button
// TODO - add copy to clipboard button for each message https://necolas.github.io/react-native-web/docs/clipboard/ and https://github.com/react-native-clipboard/clipboard
// TODO remove self-interrogate when disconnected from ws????


// TODO borrow UX elements from bng discover
// TODO use this for WIP features: Linking.openURL("https://twitter.com/stackhub_discover/status/1658399331513257984")

// TODO on disconnect: clear

const Discover = () => {
  const { colors } = useTheme(); // Named colors
  const { ws, status } = useContext(WebSocketContext);
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState<Array<{ sender: string, message: string, messageElement?: ReactElement<any, any> }>>([]);
  type ClientState = 'waiting' | 'editable' | 'connecting';
  const [clientState, setClientState] = useState<ClientState>('waiting');
  const [dummyState, setDummyState] = useState(false);
  const [showCustomContent, setShowCustomContent] = useState("");
  const [answerType, setAnswerType] = useState('');
  const [serverState, setServerState] = useState('STATE_WAITING_FOR_INIT');
  const [streaming, setStreaming] = useState(false);
  const [stateInfo, setStateInfo] = useState({});

  // Keyboard tuning for different platforms
  const headerHeight = useHeaderHeight();
  const keyboardVerticalOffset = Platform.OS === 'web' ? 0 : headerHeight;
  const keyboardBehavior = Platform.OS === 'ios' ? 'padding' : undefined;

  // captureMessage('Home screen loaded');
  // TODO sentry source maps. Get key from https://stackhub-discover.sentry.io/settings/account/api/auth-tokens/
  // here how to set it up in combo with EAS https://docs.expo.dev/guides/using-sentry/#app-configuration

  // Show notification if connection is lost
  useFocusEffect(
    React.useCallback(() => {
      let toastTimeout: NodeJS.Timeout | null = null;

      if (status.type === 'connecting') {
        toastTimeout = setTimeout(() => {
          Toast.show({
            type: 'info',
            text1: 'Connecting...',
            autoHide: false,
            topOffset: 100,
          });
        }, 1000);  // 1 second delay
      } else if (status.type !== 'connected') {
        setClientState("connecting");
        Toast.show({
          type: 'error',
          text1: 'No connection',
          text2: getStatusDisplay(status),
          autoHide: false,
          topOffset: 100,
        });
      } else if (status.type === 'connected') {
        setClientState("editable");
        if (toastTimeout) clearTimeout(toastTimeout);
        Toast.hide();
      }

      return () => {
        if (toastTimeout) clearTimeout(toastTimeout);
        Toast.hide();
      };
    }, [status])
  );

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (clientState === "waiting") {
      // Start a 60 seconds timer when editable becomes false
      timerRef.current = setTimeout(() => {
        // If editable is still false after RESPONSE_TIMEOUT_MS seconds, close the websocket connection
        // TODO do this different, don't lose state also show message Toast
        // Maybe use: setServerState('STATE_WAITING_FOR_INIT');
        console.log('Response timeout: ', RESPONSE_TIMEOUT_MS, ' ms');
        if (clientState === "waiting" && ws && ws.readyState === WebSocket.OPEN) {
          ws.close();
        }
      }, RESPONSE_TIMEOUT_MS);
    } else {
      // If editable becomes true before the timer completes, clear the timer
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }

    return () => {
      // Clear the timer when the component unmounts
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [clientState, ws]);

  // Listen for messages from the server
  const streamingMessageRef = useRef<{ sender: string, message: string, messageElement?: ReactElement<any, any> } | undefined>(undefined);
  const tempChunkStorageRef = useRef('');


  useEffect(() => {
    const fetchKey = async () => {
      let storedKey = '';
      if (Platform.OS === 'web') {
        storedKey = await AsyncStorage.getItem('openAIKey') || '';
      } else {
        storedKey = await SecureStore.getItemAsync('openAIKey') || '';
      }
      const keyFormat = new RegExp('^sk-[a-zA-Z0-9]{32,}$');
      if (keyFormat.test(storedKey)) {
        return storedKey;
      } else {
        return null;
      }
    }

    const initialize = async () => {
      const key = await fetchKey();
      if (ws !== null && status.type === 'connected') {
        if (serverState === 'STATE_WAITING_FOR_INIT') {
          if (key !== null) {
            sendMessage('command', 'init', { 'openai_api_key': key });
          } else {
            sendMessage('command', 'init');
          }
        }
      }
    }

    if (ws !== null && status.type === 'connected') {

      if (serverState === 'STATE_WAITING_FOR_INIT') {
        setChatHistory([]);
        setShowCustomContent('');
        streamingMessageRef.current = undefined;
        initialize();
      }

      ws.onmessage = (event) => {
        const messageData = JSON.parse(event.data);

        console.log('>>>>>>>>>>>', messageData);

        setServerState(messageData.state);
        setStreaming(messageData.type === 'stream')

        if (messageData.type === "init_ack") {
          //streamingMessageRef.current = undefined;
          setShowCustomContent(messageData.message);
          setClientState("editable");
        } else if (messageData.type === "start") {
          console.log("start");

          streamingMessageRef.current = { sender: messageData.sender, message: 'ActivityIndicator' };
          tempChunkStorageRef.current = '';
          setDummyState(prev => !prev);
          setAnswerType('');
        } else if (messageData.type === "stream") {
          let newChunk = messageData.message;
          console.log("stream", newChunk);

          if (!answerType) setAnswerType(messageData['response_type']);

          // Check if the chunk is part of a markdown link
          if (newChunk.includes('[') && !newChunk.includes(')')) {
            // This chunk is the start of a markdown link, store it temporarily
            tempChunkStorageRef.current += newChunk;
          } else if (tempChunkStorageRef.current !== '' && !newChunk.includes(')')) {
            // This chunk is the continuation of a markdown link, store it temporarily
            tempChunkStorageRef.current += newChunk;
          } else {
            // This chunk is either the end of a markdown link or not part of a link
            newChunk = tempChunkStorageRef.current + newChunk;
            tempChunkStorageRef.current = '';
            // Strip the spinner if it is there
            if (streamingMessageRef.current?.message === 'ActivityIndicator') streamingMessageRef.current.message = '';
            // Append the new chunk to the current message
            let newMessage = streamingMessageRef.current ? streamingMessageRef.current.message + newChunk : newChunk;

            if (newMessage.length > 0) {
              streamingMessageRef.current = {
                sender: messageData.sender,
                message: newMessage,
              };
              // Trigger a render by updating a dummy state
              setDummyState(prev => !prev);
            }
          }
        } else if (messageData.type === "end") {
          console.log('end', streamingMessageRef.current);

          if (streamingMessageRef.current !== undefined) {
            setChatHistory((prevMessages) => [
              ...prevMessages,
              ...(streamingMessageRef.current ? [streamingMessageRef.current] : [])
            ]);
          }
          setStateInfo(messageData.info);

          setClientState("editable");
          setDummyState(prev => !prev);

        } else if (messageData.type === "error") {
          setAnswerType('');
          setClientState("editable");
          setDummyState(prev => !prev);
          streamingMessageRef.current = undefined;
          setChatHistory([]);
          setShowCustomContent('');
          Toast.show({
            type: 'error',
            text1: messageData.message,
            autoHide: true,
            visibilityTime: 5000,
            topOffset: 100,
          });
        }
      };
    } else {
      setServerState('STATE_WAITING_FOR_INIT');
    }
    return () => {
      if (ws !== null) {
        ws.onmessage = null;
      }
    };
  }, [ws, status.type, serverState, answerType]);

  type ChatResponse = {
    type: string,
    message: string,
    sender: string,
    info?: object,
  }

  const sendMessage = (msg_type = 'query', cmd_message = '', info = {}) => {
    if (msg_type === 'query' && message.trim() === "") {
      return;
    }

    if (ws !== null && status.type === 'connected') {
      const response: ChatResponse = { type: msg_type, message: message, sender: 'human' };
      if (msg_type === 'command' && cmd_message !== '') {
        response['message'] = cmd_message;
        if (!_.isEmpty(info)) {
          response['info'] = info;
        }
        ws.send(JSON.stringify(response));
        if (cmd_message !== 'init') streamingMessageRef.current = { sender: 'bot', message: 'ActivityIndicator' };
      } else {
        ws.send(JSON.stringify(response));
        streamingMessageRef.current = undefined;
        setChatHistory([...chatHistory, { sender: 'human', message: message }]);
        setMessage('');
        if (textInputRef.current && Platform.OS === 'web') {
          textInputRef.current.style.height = 'auto';
        }
      }

      // disable input till bot responds
      setClientState("waiting");

      // Remove the custom content when user sends a new message
      setShowCustomContent('');
    }
  };

  if (chatHistory) console.log('chatHistory', chatHistory);
  console.log(answerType);

  const clearMessages = () => {
    setServerState('STATE_WAITING_FOR_INIT');
  };

  const renderMessages = () => {
    let allMessages = [...chatHistory];
    if (
      (chatHistory.length === 0 ||
        chatHistory[chatHistory.length - 1].message !== streamingMessageRef.current?.message ||
        chatHistory[chatHistory.length - 1].sender !== streamingMessageRef.current.sender
      ) && (streamingMessageRef.current)) {
      allMessages.push(streamingMessageRef.current);
    }

    console.log('streamingMessageRef', streamingMessageRef.current);
    console.log('allMessages', allMessages);

    if (allMessages.length === 0) {
      allMessages.push({
        sender: 'bot', message: (`Hi, there! I'm StackHub, your personal aid in finding open-source software.\n\nPlease let me know what you are looking for.\n\nProvide as much detail as possible.`)
      });
    }

    if (showCustomContent) {
      // Add your custom component as the last item in the array
      allMessages.push({ sender: 'custom', message: showCustomContent });
    }

    if (__DEV__) {
      allMessages.push({ sender: 'DEBUG', message: '' });
    }

    return allMessages.map((msg, index) => {
      if (msg.sender === 'DEBUG') {
        console.log('DEBUG', stateInfo);
        return (
          <View
            key={index}
            style={styles.customContent}
          >
            <MyText style={styles.customText}>Server State: {serverState}</MyText>
            <MyText style={styles.customText}>Response Type: {answerType}</MyText>
          </View>
        );
      } else {
        if (!msg || (!msg.message && !msg.messageElement)) return null;
        console.log('msg.message', msg.message);

        const msgTextStyle = {color: msg.sender === 'bot' ? C.grey900 : "white" };

        let messageText;
        if (msg.message === 'ActivityIndicator') messageText = <ActivityIndicator color={C.grey700} />
        else if (msg.messageElement) messageText = msg.messageElement;
        else if (typeof msg.message === 'string') messageText = parseMarkdown(msg.message, msgTextStyle);
        else messageText = msg.message;

        console.log('[serverState]', serverState);
        console.log('messageText', messageText)

        return (
          <View key={index} style={[styles.message, {
            backgroundColor: msg.sender === 'bot' ? C.blue200 : C.blue600,
            borderTopRightRadius: msg.sender === 'bot' ? 10 : 0,
            borderTopLeftRadius: msg.sender === 'bot' ? 0 : 10,
          }]}>
            <View style={styles.messageContent}>
              {messageText}
            </View>
          </View>
        );
      }
    });
  };

  const scrollViewRef = useRef<ScrollView>(null);
  const textInputRef = useRef<any>(null);

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={keyboardBehavior}
        keyboardVerticalOffset={keyboardVerticalOffset}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <ScrollView
            style={styles.outputArea}
            contentContainerStyle={{ flexGrow: 1 }}
            ref={scrollViewRef}
            onContentSizeChange={() => scrollViewRef.current?.scrollToEnd({ animated: true })}
            showsVerticalScrollIndicator={false}
          >
            <View style={styles.contentWrapper}>
              {renderMessages()}
            </View>
          </ScrollView>
        </TouchableWithoutFeedback>
        <View style={styles.inputArea}>
          <View style={styles.inputWrapper}>
            <Ionicons name="brush" size={28} color={clientState === "editable" ? C.grey400 : C.grey200} onPress={() => clientState && clearMessages()} />
            <TextInput
              ref={textInputRef}
              style={[
                styles.textInput,
                clientState !== "editable" ? styles.disabledInput : null,  // Conditional styling
              ]}
              multiline
              placeholder={getPlaceholder(clientState, serverState, streaming)}
              placeholderTextColor={C.grey400}
              autoFocus={Platform.OS === 'web' ? true : false}
              autoComplete="off"
              autoCorrect={false}
              autoCapitalize="none"
              onChangeText={newMessage => setMessage(newMessage)}
              value={message}
              onChange={Platform.OS === 'web' ? adjustTextInputSizeWeb : () => { }}
              //returnKeyType="send"
              onSubmitEditing={(evt) => sendMessage()}
              editable={clientState === "editable"}
              onKeyPress={({ nativeEvent }) => {
                if (
                  Platform.OS === "web" &&
                  !Keyboard.isVisible &&
                  !isMobileDevice() &&
                  // @ts-ignore shiftKey works for web!
                  nativeEvent.key === "Enter" && !nativeEvent.shiftKey
                ) {
                  sendMessage();
                }
              }}
            />
            <Ionicons name="paper-plane-sharp" size={28} color={clientState === "editable" ? C.blue500 : C.blue200} onPress={() => clientState && sendMessage()} />
          </View>
        </View>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

export default Discover;
