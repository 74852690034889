import { useState, useEffect, useRef } from 'react';

export type WebSocketStatus =
  | { type: 'connecting' }
  | { type: 'reconnecting'; nextAttempt?: number }
  | { type: 'connected' }
  | { type: 'error'; message: string };


export const useWebSocket = (url: string) => {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [status, setStatus] = useState<WebSocketStatus>({ type: 'connecting' });
  const reconnectTimeout = useRef<NodeJS.Timeout | null>(null);
  const reconnectDelay = useRef(1000);

  const connect = () => {
    const newWs = new WebSocket(url);

    newWs.onopen = () => {
      console.log('WebSocket connected');
      reconnectDelay.current = 1000;
      setStatus({ type: 'connected' });
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
        reconnectTimeout.current = null;
        reconnectDelay.current = 1000;
      }
    };

    newWs.onclose = (event: CloseEvent) => {
      console.log('WebSocket disconnected, reason:', event.code);
      if (!reconnectTimeout.current) {
        reconnectTimeout.current = setTimeout(() => {
          reconnectTimeout.current = null;
          reconnectDelay.current = Math.min(16000, 2 * reconnectDelay.current);
          connect();
        }, reconnectDelay.current);
        setStatus({ type: 'reconnecting', nextAttempt: Date.now() + reconnectDelay.current });
      }
    };

    newWs.onerror = (error: Event) => {
      setStatus({ type: 'error', message: "Connection lost" });
      newWs.close();
    };

    setWs(newWs);
  };

  useEffect(() => {
    connect();
    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
    };
  }, []);

  return { ws, status };
};
